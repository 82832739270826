.App {

  overflow-x: hidden;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

::-webkit-scrollbar {
  display: none;
}
.hidden
{
  display: none !important;
}

select
{
  border: transparent !important;
  outline: none !important;
}