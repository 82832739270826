.scrollButton
{
    position: fixed;
    bottom: 5%;
    right: 2%;
    background: #111;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0;
    transition: 0.4s ease;
    z-index: 999;
    cursor: pointer;
    pointer-events: none;
    
}

.scrollButton:hover
{
    transform: translateY(-2px);
    transition: 0.3s ease;
}