.roomBookingSectionWrapper{
    background-color: var(--primary-background);
}

.hallFilterSectionWrapper{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 2em 0;

    
}

.hallTypeSelector{
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;

    gap: 5em;
}

.hallType{
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 50px;
    padding: 6px 30px;

    transition: all 0.3s ease-in-out;

    font-size:1.2em;
    cursor: pointer;
}

.hallType:hover{
    background-color:#111;
    color: #fff;
}

.activeHall,.activeHall:hover{
 background-color: var(--primary-color);
 border-color: var(--primary-color);
 color: white;

}


.hallBookingBannerSection
{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vh;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 5px;
    font-weight: 800;
    width: 100%;
    height: 50vh;
    margin-top: 10vh;
    background: url('https://images.unsplash.com/photo-1542665952-14513db15293?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80') rgba(17, 17, 17, 0.519);
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    color: #fff;

}

.hallRoomBookingReservationSection
{
    background: transparent;
    width: 100%;
}

.conferenceHallBookingSection
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
}


@media screen and (max-width:900px){
    .hallTypeSelector
    {
        gap: 0.2em;
        margin-bottom: 10vh;
    }

}