.feedbackSection{
    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-direction: column;
    width: 20vw;

}

.feedbackTitle{
    font-size: 2em;
    font-weight: 600;

}

.feedbackSection .sendMsgBtn{
    margin: 1em 0;

    width: 100%;
    padding: 0.2em;

    color: white;
    text-align: center;

}
.feedbackSection .sendMsgBtn:hover{
    color: black;
}

@media screen and (max-width:900px) {
    .feedbackSection{
        width: 80vw;
    }
}