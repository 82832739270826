.reservationSection{
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    margin-top: 3vh;

    gap:1em;

}

.form-control{
    background-color: #e5e5e5;
    outline: none;
    border: none;
}
.form-control:focus{
    background-color: #e5e5e5;
    outline: none;
    border: none;
}


.submitBtn{
    width: 100%;

    margin:10px auto;
    padding: 12px 20px;

    background-color: black;
    outline: none;
    border: 2px solid rgb(255, 255, 255);

    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
}
.submitBtn:hover{
    background-color: var(--primary-color);
    outline: none;
    border: 2px solid var(--primary-color);
    color: black;
}
.reservationSection .form-field
{
    position: relative;
    margin: 10px 0;
}
.reservationSection .form-field i
{
    position: absolute;
    right: 2%;
    top: 10%;
}

