.videoModal {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  height: 120vh;

  overflow-y: scroll;
  /* top: 0; */
  left: 0;

  z-index: 9999;

  position: fixed;

  background-color: #0000009f;

  user-select: none;

  backdrop-filter: blur(4px);
}

.videoWrapper {
  background-color: transparent;

  /* padding: 2em; */

  border-radius: 1em;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  gap: 1em;
}

.closeVideoBtn {
  font-size: 3em;

  cursor: pointer;

  align-self: flex-end;
  color: var(--primary-color);
}

.videoWrapper iframe {
  height: 70vh;
  width: min(1000px,90vw);
}

