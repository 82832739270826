.roomBookWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 2em;

  box-shadow: 1px 1px 10px black;

  height: 10vh;
  width: max-content;
}

.infoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;

  width: 100%;
  height: 100%;

  padding-left: 1.5em;
}

.info {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.infoText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookSection {
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  margin-top: -18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
