.roomSection {
  background-color: var(--primary-background);
  min-height: 100vh;
}
/* .roomSection .header
{
  padding: 0.5em 5vw;
}

.header .header{
  padding: 0 0.5em ;
} */

.heading {
  margin: 1em 8vw;
}

.ourRooms {
  margin-bottom: -0.8em !important;
}

.roomSection .header div {
  font-size: 70%;
  font-weight: 400;
}
.roomSection .gallery {
  overflow: hidden !important;
}
@media screen and (max-width: 800px) {
  .roomSection .header {
    /* padding: 0.5em 5vw; */
  }
  .heading {
    /* text-align: center; */
  }
}
