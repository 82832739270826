.hallRoomSection {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: left;

  background-color: var(--primary-background);

  padding: 4em 0;
}

.sectionWrapper {
  display: flex;

  width: 100%;

  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;
}
.hallRoomSection .header {
  margin: 1em 5em;
}
.gallery {
  justify-self: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5em;
}

.galleryCol {
  width: 30vw;
  position: relative;
  height: 80vh;
}
.galleryCol img {
  width: 100%;
  height: 100%;
}

.leftWrapper,
.rightWrapper,
.centralWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 0.5em;
}
.centralWrapper {
  width: 30vw;
}
.rightWrapper {
  width: 20vw;
}
.centralTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5em;
}
.centralTop img:nth-of-type(1) {
  width: 60%;
}
.centralTop img:nth-of-type(2) {
  width: 38%;
}

@media screen and (max-width: 800px) {
  .gallery {
    flex-direction: column;
  }
  .leftWrapper,
  .rightWrapper,
  .centralWrapper,
  .centralTop {
    width: 90vw;
    height: auto;
  }
  .hallRoomSection .header {
    margin: 1em !important;
  }
  .hallRoomSection .header .aboutSectionTitle {
    font-size: 32px;
  }
}
