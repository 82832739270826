.roomBookingSectionWrapper
{
    width: 100vw;
    padding: 2.5em;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 5vh; */

}

.roomBookingBannerSection
{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vh;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 5px;
    font-weight: 800;
    width: 100%;
    height: 50vh;
    background: url('../../assets/images/image\ 1.png') rgba(17, 17, 17, 0.519);
    background-size: contain;
    background-position: center;
    background-blend-mode: darken;
    color: #fff;

}

.roomFilterSectionWrapper
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2vh;
}
.roomTypeSelector
{
    margin: 20px;
    background: transparent;
    padding: 6px 12px;
}
.filterDesc
{
    font-size: 18px;
    font-weight: 800;
    font-family: 'Alegreya',sans-serif;
}
.roomTypeSelector select
{
    background: transparent;
    border: none;
    outline: none;
    padding: 12px;
    
}

.roomBookingSection
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 90vw;
    margin: 3vh auto;

}

.roomBookingCard
{
    width: 25vw;
    margin: 2vh 1vw;
    min-height: 400px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    opacity: 0.9;
    transition: 0.4s ease;
}

.roomBookingCard:hover{
opacity: 1;
}
.bookedCard
{
    opacity: 0.6;
    pointer-events: none;
}


.roomBookingCardImage
{
    width: 100%;
    overflow: hidden;
}

.roomBookingCardImage img
{
    width: 100%;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.roomBookingCardDetails
{
    padding: 0.5em 2em;
    
}
.roomType
{
    font-size: 200%;
    font-family: 'Alegreya',serif;
}
.price span
{
    font-size: 150%;
    font-family: 'Alegreya',serif;
    margin: 0 10px;
}
.amenities,.amenityWrapper
{
    display: flex;
    align-items: center;

}
.amenities{
flex-wrap: wrap;
justify-content: flex-start;
}
.amenityWrapper
{
    font-size: 30px;
    color: #fba420;
    margin: 10px;
}
.amenityWrapper:nth-of-type(1)
{
    margin-left: 0;
}
.amenityWrapper span
{
    color: #111;
    font-size: 15px;
    margin-left: 12px;
}
.bookNowBtn
{
    width: 100%;
    margin: 20px auto;
    padding: 12px 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    background: #252525;
    color: #fff;
    border: 1px solid transparent;
    font-size: 100%;
    cursor: pointer;
    transition: 0.4s ease;
    border-radius: 5px;
}
.bookNowBtn:hover
{
    background: #fba420;
    border: 1px solid #fba420;
}

@media screen and (max-width:900px) {
    .roomBookingCard
    {
        width: 40vw;
    }
}
@media screen and (max-width:600px) {
    .roomBookingSectionWrapper
    {
        padding: 0 !important;
    }
    .roomBookingCard
    {
        width: 90vw;
    }
    .roomFilterSectionWrapper
    {
        justify-content: flex-start;
        padding: 0 5vw;

    }
    .roomTypeSelector
    {
        margin: 10px 0;
        padding: 0;
    }
    .roomTypeSelector select
    {
        padding: 6px;
    }
}


