.aboutSection {
  display: flex;
  flex-direction: column;

  gap: 2em;

  text-align: left;

  margin: 5em 5em;

  font-family: 'Outfit';
  font-size: 24px;
  color: var(--gray-color);

  min-height: 100vh;
}
.aboutSectionTitle {
  font-weight: 600;
  font-size: 54px;
}
.header {
  font-weight: 600;
  font-size: 30px;

  margin-bottom: 1.1em;
}

.amenitiesIconQueue {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  flex-wrap: wrap;
  text-align: center;

  margin: 1em auto;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  margin:  10px auto;
  text-align: center; */

}
.amenity
{
  margin: 20px;
  margin:  10px auto;
}
.amenityIcon
{
  height: 70px;
}
.amenityLabel
{
  font-size: 18px;
}
.links {
  color: var(--primary-color);
  text-decoration: none;
}

.links:visited {
  color: var(--primary-color);
}


@media screen and (max-width:900px) {
  .aboutSection
  {
    margin: 1em;
    font-size: 18px;
    margin-top: 10vh;
  }
  .amenitiesIconQueue
  {
    align-items: center;
    justify-content: space-evenly;
  }
  .amenity
  {
    width: 20vw;
    padding: 0;
    /* height: 120px; */
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .amenityIcon{
    height: 60px;
  }
  .amenityIcon img
  {
   width: 80%;
   /* height: 30px; */
  }
  .amenityLabel
  {
    font-size: 100%;
    font-weight: 600;
  }
  
}