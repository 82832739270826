.landingSectionWrapper {
  min-height: 100vh;
  width: 100vw;
  background-color: var(--primary-background);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  /* /* overflow-x: hidden; */

  font-family: 'Outfit';
  font-weight: 600;
}

.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  width: 60vw;
  min-height: 100vh;
}

.landingLeft {
  width: 40vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.landingLeft .landingDetails {
  width: 80%;
  color: #454545;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20%;
  /* border: 1px solid violet; */
}

.landingRight {
  position: relative;
  overflow: hidden;
}
.landingRight img {
  width: auto;
  height: 100vh;
  position: relative;
  left: 0;
}
.landingDetails h2 {
  font-size: 50px;
  font-weight: 700;
  align-self: flex-start;
}
.landingLeft h4 {
  width: 90%;
  font-size: 20px;
  margin-top: 3%;
  align-self: flex-start;
  font-weight: 400;
}

.videoSection {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
  font-size: 50px;
  color: var(--primary-color);
  margin-top: 5%;

  cursor: pointer;
}

.videoSection span {
  color: #454545;
  font-size: 25px;
  margin-left: 20px;
}

.titleLogoSection img {
  width: 6em;
}

.landingForm {
  width: 80%;
  background: #fff;
  height: 90px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.16);
  flex: 1;
  position: absolute;
  top: 75%;
}
.landingForm form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  /* padding-left: 5%; */
}
.landingFormDivWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.landingFormDivWrapper label {
  font-size: 40px;
  color: var(--primary-color);
}
.landingFormInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5%;

  width: 100%;
}
.landingFormInputWrapper span {
  font-weight: 600;
}
.landingFormInputWrapper input, .landingFormInputWrapper select {
  width: 80%;
  font-size: 0.7em;
  border: none;
  outline: none;
}
.landingFormInputWrapper select
{
  width: 100%;
}
.guestInput {
  width: 60%;
}
.landingRight .landingForm {
  width: max-content;
  left: 0;
  /* right: 0; */
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* margin-top: -8px; */
  background: transparent;
}
.landingRight .landingForm button {
  background: var(--primary-color);
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 25px;
  padding: 12px 20px;
  transition: 0.5s ease;
  /* margin-top: -6px; */
}
.landingRight .landingForm button:hover {
  background: #454545;
  color: #fff;
}

.landingSectionMobile
{
  display: none;
}

@media screen and (max-width:900px) {
  .landingSectionWrapper
  {
    display: none;
  }

  .landingSectionMobile
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    min-height: 60vh;
    background: #e5e5e5;
    background: url('../../assets/Shivangan_CoverImg.png') rgba(17, 17, 17, 0.61);
    background-blend-mode: darken;
    background-size: cover;
    background-position: center;
    margin-top: 5vh;
    position: relative;
    
  }

  .weatherWrapper
  {
    width: 90vw;
    margin: 0 auto;
    background: #fff;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    position: absolute;
    top: -5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
  .weatherWrapper > div{
    width: 20vw;
    text-align: center;
  }
  .greetings{
    font-size: 1.2em;
    font-weight: 600;

  }

  .landingDetails
  {
    color: #fff;
    padding: 0 1em;
  

  }
  .landingDetails h2
  {
    font-size: 30px;
    font-weight: 600;
  }

  .landingDetails h4 
  {
    font-weight: 200;
    font-size: 18px;
    margin: 10px 0;
  }
  .videoSection span
  {
    color: #fff;
  }


  .weather
  {
    display: flex;
    align-items: center;
    
  }
  .weatherDetails
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  #temperature
  {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
  }
  #weatherDesc
  {
    font-size: 15px;
    margin-top: -3px;
  }
  .timing
  {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #time
  {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
  }

  #day
  {
    font-size: 15px;
    margin-top: -3px;
  }
  .landingForm
  {
    width: 95vw;
    border-radius: 10px;
    position: absolute;
    bottom: -10%;
  }
  .landingFormDivWrapper
  {
    width: 20%;
  }
  .landingFormDivWrapper label
  {
      font-size: 20px;
  }
  .landingFormInputWrapper span
  {
    font-size: 60%;
  }
  .bookingBtn
  {
    width: 80vw;
    height: 50px;
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: absolute;
    top: 93%;
  }
}








