.navSection {
  display: flex;
  justify-content: space-between;
  align-items: center ;

  background-color: transparent;
  width: 100vw;
  height: 15vh;
  padding: 1em 2.5vw;
  position: absolute;
  top: 0;
  z-index: 999;
}



#shivanganTitle
{
  display: none;
}

@media screen and (max-width:900px) {
  .navSection
  {
    position: relative;
    padding:0;
  }
  .titleLogoSection,.navSection h3 ,.sideNav
  {
    width: 30vw;
  }
  .titleLogoSection
  {
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .titleLogoSection img
  {
    width: 50px !important;
  }
  #shivanganTitle
  {
    display: inline-block;
    color: var(--primary-color);
    font-weight: 600;
  }
}