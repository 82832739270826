.testimonial {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  gap: 3em;

  padding: 2em 0;
}

.testimonial .title,
.titleQuote {
  align-self: flex-start;
  text-align: left;
  padding-left: 1em;

  font-weight: 500;
  margin-top: -1em;

  padding: 0 8vw;
}

.titleQuote {
  font-size: 40px;
  font-family: 'Outfit';
  font-weight: 700;
}

.testWrapper {
  width: 75%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 4em;
}

.imageWrapper {
  height: 90vh;
  width: auto;
}

.imageWrapper img {
  height: 100%;
  width: auto;
}

.customerReviewWrapper {
  min-height: 85vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: column;

  font-size: 22px;
  font-family: 'Alegreya';
  font-weight: 400;

  gap: 2.5em;
}

.mainReview {
  text-align: justify;
}

.fromCust {
  margin-top: 0.4em;
  text-align: right;

  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .testWrapper {
    flex-direction: column;
  }
  .imageWrapper {
    display: none;
  }

  .titleQuote {
    font-size: 1.8em;
    padding: 0.5em;
    padding-left: 1em;
  }
  .clients {
    grid-template-columns: repeat(2, 40vw);
  }
}
