.footerSection {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  background-color: black;

  color: white;

  font-family: 'Outfit';
}

.reachoutBanner {
  display: flex;

  justify-content: space-evenly;

  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em 2em;
  background-color: var(--primary-color);

  font-size: 36px;
  font-family: 'Outfit';
  font-weight: 700;

  text-align: center;

  color: black;
}

.reachoutBtn {
  background-color: white;
  padding: 0.4em 1.4em;

  border-radius: 46px;

  font-weight: 600;

  box-shadow: 2px 4px 2px #83838383;

  transition: all 0.2s ease-in-out;
}

.reachoutBtn:hover {
  background-color: #222;
  color: #eee;
}

.reachoutBtn:active {
  background-color: black;
  color: white;
}

.footerMainSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  flex-wrap: wrap;

  text-align: left;

  margin: 2em 4em;
}
.footerDivWrapper
{
  width: 30%;
}
.aboutUsFooter .header {
  font-size: 32px;
}

.aboutUsFooter {
  font-size: 20px;
}

.contactInfo {
  display: flex;
  flex-direction: column;

  gap: 1.4em;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 1em;

  font-size: 20px;
}

.otherLinks {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  flex-direction: column;
  width: max-content;
}

.linksGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 0.5em;

  font-size: 24px;
}

.linksGrid a {
  color: white !important;
}

.footerNewsletter {
  margin: 1em;

  font-size: 20px;

  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  gap: 1em;
}

.newsletter input,
.newsletter button {
  font-family: 'Outfit';

  background-color: transparent;

  border: 1px solid var(--primary-color);

  font-size: 20px;
  padding: 0.4em;

  border-radius: 10px 0 0 10px;

  outline: none;
}
.newsletter button {
  background-color: var(--primary-color);

  outline: none;

  border-radius: 0 10px 10px 0;

  font-weight: 600;
}

.newsletter input:focus {
  color: #fefefe;
}
.newsletter input {
  color: #eee;
}

.footerBottomBar {
  background-color: var(--primary-color);
  color: black;

  font-size: 20px;
  font-weight: 500;
}


@media screen and (max-width:900px) {
  .reachoutBanner
  {
    padding: 4vh 5vw;
    font-size: 20px;
    align-items: center;
    justify-content: center;
  }
  .reachoutBtn
  {
    width: 90%;
    margin-top: 12px;
  }
  .footerMainSection
  {
    padding: 4vh 2.5vw;
    margin: 2vh 5vw;
  }

  .footerMainSection div 
  {
    margin: 10px 0;
  }

  .footerDivWrapper
  {
    width: 90vw;
  }
  .aboutUsFooter
  {
    font-size: 18px;
  }
  .contactInfo .contact
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 ;
    font-weight: 400;
  }
  .otherLink
  {
    font-size: 18px;

  }
  .contactInfo
  {
    gap: 0;
    font-weight: 300;
  }
  .newsletter
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .newsletter, .newsletter input{
    width:95%;
    border-radius: 0.5em 0.5em 0 0;
    font-size: 18px;
  }
  .newsletter button{
    width: 95%;
    border-radius: 0 0 0.5em 0.5em;
    font-size: 15px;
  }
  .footerBottomBar
  {
      font-size: 15px;
      padding: 1vh 0;
  }
}

