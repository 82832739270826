.clientSection {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin: 2em 0;

  gap: 1em;

  min-height: 100vh;

  background-color: white;
}

.title {
  font-size: 2em;
  font-family: 'Outfit';
  font-weight: 600;
}

.clientsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clients {
  width: 90vw;
  display: grid;
  place-items: center;
  column-gap: 20px;
  grid-template-columns: repeat(4, 20vw);
}

.client {
  height: auto;
  width: 70%;
  max-width: 400px;
}

.client img {
  height: 100%;
  width: 100%;
  filter:grayscale(1);
  transition: 0.4s ease;
}
.client img:hover
{
  filter: grayscale(0);
}
.separator {
  width: 90vw;
  height: 2px;

  background-color: #00000040;

  border-radius: 1px;
}
