.bookingModal{
    min-width: 100vw;
    height: 120vh;
    background-color: #000000aa;

    position: fixed;
    top: 0;


    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20vh 0;

    z-index: 1000;
    overflow-y: scroll;

}

.modalWrapper{
    background-color: #fff;

    border-radius: 0.5em;
    
    padding: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    gap:1em;

    max-width: 80vw;
    min-width: 50vw;
    margin-top: 20vh ;
    /* margin: 30vh 0; */
    transform: scale(0.95);
}

.modalTop{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
}

.modalTop .modalTitle{
    font-size: 1.8em;
    font-weight: 600;

    width: 100%;

    text-align: center;
}

.modalTop .crossBtn{
    font-size: 2em;
    color: black;

    background-color: #eee;
    padding: 0 0.45em;

    border-radius: 50%;

    cursor: pointer;

    position: absolute;
    right: 0;
    top: 0;
}

.modalMain{
    width: 100%;
}

.modalMain form{
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 1em;
}

.modalField{
    display: flex;
    justify-content: center;
    align-items: flex-start;

    align-self: flex-start;

    flex-direction: column;

    width: 100%;

    gap: 2px;
}

.modalLabel{
    font-size: 1.1em;
    font-weight: 600;

    padding-left: 0.5em;
}

.modalInput{
    width: 100%;

    padding: 0.8em;

    background-color: #efefef;

    outline: none;
    border: none;
    border-radius: 5px;
}

.bookRoomBtn{
    padding: 1em 0;
    width: 100%;
  
    background-color: black;
    color: #fff;
  
    font-size: 20px;
    font-family: 'Outfit';
    font-weight: 600;
  
    border: 3px solid black;
    border-radius: 0.4em;
  
    transition: all 0.2s ease-in-out;
}
  
  .bookRoomBtn:hover,
  .bookRoomBtn:active {
    background-color: transparent;
    color: black;
  }
  

  @media screen and (max-width:900px) {
    .bookingModal
    {
        margin-bottom: 40vh;
    }
    .modalWrapper
    {
        max-width: 100vw;
        width: 95vw;
        position: absolute;
        top:10vh;
        margin-top: 0;
        padding-bottom: 30vh;
       

     
    }
  }