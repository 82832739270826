

.reviewSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: var(--primary-background);

  flex-direction: column;

  min-height: 100vh;
}

.mainReviewSection {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  margin: 0;

  gap: 3em;
}

.ratingSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  min-height: 70vh;
  margin-top: 15vh;
  background: url('https://images.pexels.com/photos/9821386/pexels-photo-9821386.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') rgba(0, 0, 0, 0.423);
  background-blend-mode: darken;
  background-size: cover;
 color: #fff;
}
.reviewSection .title {
  font-size: 6vh;
}

.ratingVal
{
  font-size: 10vh;
  font-family: 'Playfair Display', serif;
}

.customerReviews {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  flex-direction: column;

  gap: 3em;
  padding-bottom: 10vh;
}

.cancel:hover
{
  filter: brightness(0.3);
  cursor: pointer;
}
.review {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;

  max-width: 50vw;

  gap: 0.5em;
}
.custDetails {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1em;
}

.overallRating {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 40px;
}

.filterSection {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  width: 100vw;
  height: 4em;

  gap: 1em;
}

.filters {
  padding: 0.2em 1em;
  background-color: var(--neutral-color);
  border: 2px solid var(--neutral-color);

  border-radius: 1em;

  transition: all 0.2s ease-in-out;

  user-select: none;

  cursor: pointer;
}
.filters:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  color: white;
}

.activeFilter {
  background-color: transparent;
  border-color: var(--primary-color);
  border-width: 2px;
  color: var(--primary-color);
}

.custProfile {
  width: 40px;
  border-radius: 50%;
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  --star-background:#fc0;
  /* display: inline-block; */
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
  margin: none;
  padding: none;
  cursor: pointer;
}
.Stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--primary-background) var(--percent));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: none;
  padding: none; cursor: pointer;
}

.reviewMainWrapper
{
  width: 90vw;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
}

.addReviewSection
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 20px;
  padding-bottom: 10vh;
}
.reviewText
{
  font-size: 130%;
}
.reviewModalBtn
{
  background: rgb(17, 17, 17);
  color: #fff;
  padding: 12px 20px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.4s ease;
  width: 100%;
}

.reviewModalBtn:hover
{
  background:goldenrod;
  
}

.reviewModalWrapper
{
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.548);
  backdrop-filter: blur(4px);
  pointer-events: none;
}
.modalActive
{
  display: flex;
  pointer-events: all;
  z-index: 999999999;
}

.reviewTextArea
{
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.53);
  resize: none;
  margin: 12px 0;
}
.reviewForm
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}

@media screen and (max-width: 900px) {
  .reviewSection {
    padding: 0 0 4em 0;
  }
  .reviewSection .title {
    margin: 1em 0 0 0;
  }
  .filters {
    transform: scale(0.8);
    padding: none;
  }
  .filterSection {
    flex-wrap: wrap;
    gap: 0;
  }
  .review {
    max-width: 80vw;
  }
  .addReviewSection
  {
    margin-top: 5vh;
    position: relative;
  }
  .reviewMainWrapper
  {
    flex-wrap: wrap-reverse;
  }
}

/* * Review Modal Section Styling*/

.card {
  position: relative;
  width: min(400px,90vw);
  height: auto;
  padding: 2rem;
  gap: 20px;
  background: #ffffffbb;
  backdrop-filter: blur(4px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
}
.card h4 {
  color: #000;
  padding: 1rem;
  text-align: center;
}
.rating-container {
  background: var(--primary-background);
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  /*   margin: 0 20px; */
  text-align: center;
  color: #000;
  border-radius: 1em;
  width: 100%;
}
.rating {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
}
.rating svg {
  width: 35px;
  height: 35px;
  color: gray;
  transition: 0.4s ease;
}
.rating svg:hover {
  color: var(--primary-color);
  /*   position:absolute; */
  fill: #ffc400;
}
.reviewSubmitBtn {
  padding: 0.8rem;
  border: 2px solid goldenrod;
  background-color: goldenrod;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width:  100% ;
  margin-top: 20px;
}
.reviewSubmitBtn:hover {
  background-color: transparent;
  border: 2px solid #000;
  box-shadow: 2px 3px 10px rgba(255, 255, 255, 0.2);
}
.link {
  text-decoration: none;
  text-align: center;
  color: #888888;
  font-size: 12px;
  cursor: pointer;
}
.cancel {
  position: absolute;
  top: 10px;
  right: 15px;
  filter:brightness(0.8)
}
