@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

.sideNav {
  display: flex;
  align-items: center;

  position: relative;
}

.sideNavMain {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffffbb;
  backdrop-filter: blur(4px);
  height: 100vh;
  width: min(400px, 75vw);

  transition: all 0.2s ease-in-out;

  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;

  user-select: none;

  z-index: 100;
}

.navBtn {
  cursor: pointer;
  /* position: fixed; */
  background: #111;
  color: #fff;
  padding: 6px;
  /* margin-left:-5vw; */
}

.navList {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-left: 30%;

  flex-direction: column;

  gap: 1em;

  font-size: 2em;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
}

.navLink {
  /* color: #ffdc60; */
  color: #454545;
}

.activeNav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}
.activeNavLink
{
  color: var(--primary-color) !important;
}
.closeNavBtn {
  font-size: 48px;

  position: absolute;
  top: 0;
  right: 20px;

  cursor: pointer;
}

.active {
  color: #111 !important;
}
.navBtnIcon {
  font-size: 30px;
}
@media screen and (max-width: 900px) {
  .navLink {
    font-size: 25px;
  }
  .navBtn {
    padding: 12px;
    margin: 0;
    width: 55px;
    height: 50px;

    position: absolute;
    right: 20%;
    top: unset;
  }
  .navBtnIcon {
    font-size: 30px;
  }
}
