.reservationSectionWrapper
{
    padding-top: 10vh;background: var(--primary-background);
   
}
.reservationBannerSection
{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vh;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 5px;
    font-weight: 800;
    width: 90%;
   margin: 0 5vw;
    height: 50vh;
    background: url('../../assets/images/image\ 3.png') rgba(17, 17, 17, 0.519);
    background-size: contain;
    background-position: center;
    background-blend-mode: darken;
    color: #fff;

}

.reservationPage{
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    flex-wrap: wrap;
}
.reservationSection
{
    background: rgba(255, 255, 255, 0.567);
    padding: 2%;
    margin-bottom: 4vh;
    border-radius: 5px;
}
@media screen and (max-width:900px) {
    .reservationPage{
        padding: 1em;
    }
    .reservationSectionWrapper
    {
        margin-top: 3vh;
    }
    .reservationSection
    {
        
        padding: 1em;
    }
}