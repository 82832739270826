@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700&family=Montserrat:wght@400;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&display=swap');
:root {
  --primary-color: #ffdc60;
  --primary-background: #fffbf0;
  --gray-color: #454545;
  --neutral-color: #f1f1f1;

  --star-size: 30px;
  --star-color: #f1f1f1;
  --star-background: #fc0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a:visited,
a:active,
a:hover {
  color: #000;
}

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
