.contactWrapper {
  width: 90vw;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  flex-direction: column;

  gap: 4em;

  padding: 3em 0;
}

.contactTitle {
  font-size: 2em;
  font-family: 'Outfit';
  font-weight: 600;
}

.mainWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 200px;
}

.contactSection {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;

  gap: 1em;
}

.contactInfo {
  text-align: left;

  font-size: 24px;
  font-family: 'Outfit';
  font-weight: 600;
}

.contactLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactLogo svg {
  width: auto;
  height: 1.4em;
}

.formSection {
  padding: 2.2em;

  background-color: #fffdf6;

  filter: drop-shadow(0 4px 0.5rem #00000040);

  border-radius: 0.2em;

  min-width: 40vw;
}

.mainForm {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  gap: 1.8em;
}

.formTitle {
  font-size: 1.4em;
  font-family: 'Outfit';
  font-weight: 600;
}

fieldset{
  border: 1px solid black !important;
}

legend{
  float: none !important;
  width: fit-content !important;
}

.formField {
  width: 100%;
  height: auto;
  padding: 1em;
  margin: 0;

  border-radius: 0.4em;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.formField:nth-child(5) .formTxtArea {
  height: 5em !important;
  resize: none;
  width: 100%;
}

.formField legend {
  padding: 0;
  margin: 0;

  font-size: 1.1em;
  font-family: 'Outfit';
  font-weight: 500;

  text-align: left;
}

.formEntry,
.formTxtArea {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  font-size: 1.2em;

  background: transparent;
  outline: none;
  border: none;

  font-family: 'Outfit';
}

.sendMsgBtn {
  padding: 1em;
  width: 100%;

  background-color: var(--primary-color);
  color: #000;

  font-size: 20px;
  font-family: 'Outfit';
  font-weight: 600;

  border: 3px solid var(--primary-color);
  border-radius: 0.4em;

  transition: all 0.2s ease-in-out;
}

.sendMsgBtn:hover,
.sendMsgBtn:active {
  background-color: transparent;
  color: black;
}

@media screen and (max-width:900px) {
  .contactWrapper .mainWrapper
  {
    flex-direction: column;
    gap: 50px;
  }
  .contactWrapper .mainWrapper .formSection
  {
    width: 90vw;
  }
}